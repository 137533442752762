export default function LazyLoadImages() {
  observeImages()
}

function observeImages() {
  //Lazy Img sources or sourc sets using attr 'data-'prefixed versions of the attribute
  document.addEventListener("DOMContentLoaded", function () {
    var lazyloadSrcs = document.querySelectorAll("[data-src]");

    //IE doesn't do half the good stuff we want it to so, if we need to support it the below code just bulk loads the 'lazy' images. That's about all we can do :shrug:

    //if (detectIE() || //check for support - old ie doesn't even support 'foreach'
    //  !('IntersectionObserver' in window) ||
    //  !('IntersectionObserverEntry' in window) ||
    //  !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
    //
    //  for (var i = 0; i < lazyloadSrcs.length; i++) {
    //    lazyloadSrcs[i].src = lazyloadSrcs[i].dataset.src;
    //    lazyloadSrcs[i].removeAttribute('data-src');
    //
    //    var parent = image.parentElement;
    //    if (parent.tagName == 'PICTURE') {
    //      var childSources = parent.getElementsByTagName('source');
    //      var childSourceElems = Array.prototype.slice.call(childSources, 0);
    //      for (var i = 0; i < childSourceElems.length; i++) { //loop though source elems replacing data-srcsets
    //        childSourceElems[i].srcset = childSourceElems[i].dataset.srcset;
    //        childSourceElems[i].removeAttribute('data-srcset');
    //      }
    //    }
    //
    //    lazyloadSrcs[i].classList.remove("blur");
    //
    //  }
    //}
    //else { //use decent code if you can

      var obseverOptions = {
        rootMargin: '350px', //magic number (of how far off the page it will define as 'observable'), 350px seems to work nicely
      }

      var srcObserver = new IntersectionObserver(function (entries) {

        entries.forEach(function (entry) { 

          if (entry.isIntersecting) { //what we do when we 'observe' an image come into view
            var image = entry.target;
            image.src = image.dataset.src;
            image.removeAttribute('data-src');

            //check for srcsets in parent <picture> tag
            var parent = image.parentElement;
            if (parent.tagName == 'PICTURE') {
              var childSources = parent.getElementsByTagName('source');
              var childSourceElems = Array.prototype.slice.call(childSources, 0);
              childSourceElems.forEach(function (childSource) { //loop though source elems replacing data-srcsets
                childSource.srcset = childSource.dataset.srcset;
                childSource.removeAttribute('data-srcset');
              });
            }

            image.classList.remove("blur");
            srcObserver.unobserve(image);
          }

        });

      }, obseverOptions);

      lazyloadSrcs.forEach(function (image) {
        srcObserver.observe(image); //observe our images, wait for them to come into view
      });
    //}
  });
}

//IE check only required if we're supporting IE 10 or below.

//function detectIE() {
  //var ua = window.navigator.userAgent;

  //var msie = ua.indexOf('MSIE ');
  //if (msie > 0) {
    //// IE 10 or older => return version number
    //return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  //}

  //var trident = ua.indexOf('Trident/');
  //if (trident > 0) {
    //// IE 11 => return version number
    //var rv = ua.indexOf('rv:');
    //return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  //}

  //// other browser
  //return false;
//}