export default function LazyGoogleMaps() {
    observeMaps();
}

//default config - these values are used if nothing is passed into the function via data attributes & picked up in lazyGoogleMaps_init()
var lazyGoogleMaps_defaultConfig = {
    mapStyle: [],
    mapTypeId: "roadmap",
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: true,
    keyboardShortcuts: false,
    draggable: true,
    zoomControlOptions: {
        style: 0
    },
    zoom: 11,
    center: { lat: 53.795231, lng: -1.538458 } //ultimate fall back to Enjoy Digital office. Only really used if default (map center) lat & lng set to blank in view
};

var markerIcon = [ //svg marker
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 830 1280">',
    '<path fill="#36c593" d="M385.5 1.1c-55.5 4.4-104.3 17.6-153 41.4C86.8 113.7-4.5 264.8.3 426.5 2.2 487 15.5 542 40.9 594.5 51.8 617 59.2 629.8 74 652c6.5 9.6 85.6 136.9 176 282.7 90.3 145.9 164.6 265.3 165 265.3.4 0 74.7-119.4 165-265.2C670.4 788.9 749.5 661.6 756 652c14.8-22.2 22.2-35 33.1-57.5 42.1-86.9 52-186.9 27.9-282.1-24.4-95.8-82.2-179.5-164-237.1C583.4 26.2 497.9-.6 412.6.1c-9.4.1-21.6.5-27.1 1zM449 177.5c44 8 81.3 27.1 112 57.5 76.9 76.1 82.5 198 13 281.2-33.5 40.2-81.7 66.3-134.4 72.8-11.4 1.4-37.8 1.4-49.2 0-85.3-10.5-155-71.5-176.4-154.4-14.1-54.5-5.2-113.6 24.3-161.3 33-53.1 86.2-87.6 149.7-96.8 13-1.9 48.1-1.3 61 1z"/>',
    '</svg>'
    ].join('\n')

function observeMaps() {
    var lazyGoogleMaps = document.querySelectorAll(".lazyGoogleMap");


    if (!('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        // Older browser fallback for when Intersection Observer isn't supported

        for (var i = 0; i < lazyGoogleMaps.length; i++) {
            initMap(lazyGoogleMaps[i]);
        }
    }
    else {
        var observerOptions = {
            rootMargin: '350px',
            threshold: 0
        };
        var observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var target = entry.target;
                    initMap(target);
                    observer.unobserve(target);
                }
            });
        }, observerOptions);

        lazyGoogleMaps.forEach(function (lazyGoogleMap) {
            observer.observe(lazyGoogleMap);
        });
    }
}

function initMap(lazyGoogleMap) {

    lazyGoogleMap.classList.add("init");

    var scriptCheck = document.querySelectorAll("script[src*='//maps.googleapis.com/maps/api/js']");
    if (scriptCheck.length === 0) {
        var mapsJS = document.createElement('script');
        mapsJS.src = 'https://maps.googleapis.com/maps/api/js?callback=lazyGoogleMaps_init&key=' + lazyGoogleMap.getAttribute("data-key");
        mapsJS.async = true;
        document.getElementsByTagName('head')[0].appendChild(mapsJS);
    }
    else {
        setLazyGoogleMapsOptions();
    }
};

window.lazyGoogleMaps_init = function() { //called by Google Maps script from the page, we have to set this on the window object or it's inaccessible     
    var lazyGoogleMaps = document.querySelectorAll(".lazyGoogleMap.init");
    
    for (var i = 0; i < lazyGoogleMaps.length; i++) {
        var lazyGoogleMap = lazyGoogleMaps[i];    
        var lazyGoogleMap_config = lazyGoogleMaps_defaultConfig;
    
        if (lazyGoogleMap.hasAttribute("data-lat") && lazyGoogleMap.getAttribute("data-lat") !== "" &&
            lazyGoogleMap.hasAttribute("data-lng") && lazyGoogleMap.getAttribute("data-lng") !== "") {
            var defaultPosition = { lat: parseFloat(lazyGoogleMap.getAttribute("data-lat")), lng: parseFloat(lazyGoogleMap.getAttribute("data-lng")) };
            lazyGoogleMap_config.center = defaultPosition;
        }
    
        if (lazyGoogleMap.hasAttribute("data-zoom") && lazyGoogleMap.getAttribute("data-zoom") !== "") {
            var defaultZoom = parseFloat(lazyGoogleMap.getAttribute("data-zoom"));
            lazyGoogleMap_config.zoom = defaultZoom;
        }

        if (lazyGoogleMap.hasAttribute("data-map-type") && lazyGoogleMap.getAttribute("data-map-type") !== "") {
            var defaultMapType = lazyGoogleMap.getAttribute("data-map-type");
            lazyGoogleMap_config.mapTypeId = defaultMapType;
        }

        if (typeof(mapStyle) != "undefined") {
            var defaultMapStyle = mapStyle;
            lazyGoogleMap_config.mapStyle = defaultMapStyle;
        }

        let map;

        map = new google.maps.Map(document.getElementById("map"), {
            center: lazyGoogleMap_config.center,
            zoom: lazyGoogleMap_config.zoom,
            mapTypeId: lazyGoogleMap_config.mapTypeId == "styled_map" ? "roadmap" : lazyGoogleMap_config.mapTypeId,
            styles: lazyGoogleMap_config.mapStyle,
            mapTypeControl: lazyGoogleMap_config.mapTypeControl,
            scaleControl: lazyGoogleMap_config.scaleControl,
            fullscreenControl: lazyGoogleMap_config.fullscreenControl,
            streetViewControl: lazyGoogleMap_config.streetViewControl,
            zoomControl: lazyGoogleMap_config.zoomControl,
            keyboardShortcuts: lazyGoogleMap_config.keyboardShortcuts,
            draggable: lazyGoogleMap_config.draggable,
            zoomControlOptions: lazyGoogleMap_config.zoomControlOptions
        });

        if (lazyGoogleMap.hasAttribute("data-marker-title") && lazyGoogleMap.getAttribute("data-marker-title") !== "") {
            var defaultMarkerTitle = parseFloat(lazyGoogleMap.getAttribute("data-marker-title"));
            marker_defaultConfig.title = defaultMarkerTitle;
        }

        new google.maps.Marker({
            map,
            position: map.getCenter(),
            icon: {
                url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(markerIcon)
                , scaledSize: new google.maps.Size(42, 64)
            },
        });

        lazyGoogleMap.classList.remove("init");
        lazyGoogleMap.classList.add("loaded");
    }
}