
export default function searchBar () {
  headerSearch() // Check if cookie is currently active
 
}


function headerSearch () {

    var searchButton = document.getElementsByClassName("search-open")[0];

    searchButton.addEventListener("click", function(evt) {

     

        var container = document.getElementsByClassName("search-panel")[0];

            if(!container.classList.contains('active')){
                container.classList.add('active');
                evt.preventDefault();
            }
            else if(container.classList.contains('active')){
                container.classList.remove('active');          
            }
    }, false);


    var searchClose = document.getElementsByClassName("search-close")[0];

    

    searchClose.addEventListener("click", function() {

        var searchContainer = document.getElementsByClassName("search-panel")[0];
        var input = document.getElementById("st");

        searchContainer.classList.remove('active');
        input.value = '';
            
    }, false);

} 
