

export default function FAQ() {
    accordion()    
}

//
// Define your functions here
//
function accordion() {

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {

      this.classList.toggle("active");

      var panel = this.nextElementSibling;

      panel.classList.toggle("active");
    
    });
  }


}