import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Animations() {
    animations()    
}

//
// Define your functions here
//
function animations() {

 gsap.registerPlugin(ScrollTrigger);


const header = document.querySelectorAll('header');
const main = document.querySelectorAll('main');
const footer = document.querySelectorAll('footer');

gsap.to(header, { 
    scrollTrigger: {
        trigger: main,
        endTrigger: footer,
        start: 1,
        end: "bottom top",
        toggleClass: {targets: header, className: "scrolling"},
        toggleActions: 'play none none none' 
    }
});


//apply class is-active to all sections when they are in viewport
const sections = document.querySelectorAll('section');

sections.forEach((section) => {
    gsap.to(section, {       
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: () => `+=${section.clientHeight + 30}`,
          toggleActions: 'play reverse none reverse',
          toggleClass: {targets: section, className: "is-active"}
      }
    });

});


// fade In section
const sectionsFadeUp = document.querySelectorAll('.fadeUp');



sectionsFadeUp.forEach((section) => {
    gsap.to(section, {
        autoAlpha: 1,
        scrollTrigger: {
        trigger: section,
        start: 'top bottom-=200',
        toggleActions: 'play none none reverse'
      }
    });
    
});


}